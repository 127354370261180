import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SignUpEmail from "../../components/signUpEmail"
import img1 from "../../images/marketing-services/marketing-services-img-1.svg";
import img2 from "../../images/marketing-services/marketing-services-img-2.svg";
import img3 from "../../images/marketing-services/marketing-services-img-3.svg";
import img4 from "../../images/marketing-services/marketing-services-img-4.svg";
import img5 from "../../images/marketing-services/marketing-services-img-5.svg";
import img7 from "../../images/marketing-services/marketing-services-img-7.svg";

const servicesPage = () => (
  <Layout css="marketing-servicesPageCss">
    <SEO title="Internet Marketing Services In Boston"
         description={"Looking for the best internet marketing services in Boston? Let our team of experts help you crush your digital marketing goals and crush the competition."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1><span className={"red"}>INTERNET MARKETING SERVICES</span> IN BOSTON</h1>
          <p className={"subtitle"}>Digital marketing is the core of many business functionalities in this era. Avantcy
            Marketing is one of the leading internet marketing services in Boston, fully equipped to meet your digital
            marketing needs. Irrespective of your ultimate goal in marketing, we can help you achieve and exceed
            them.</p>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>WE ARE A DIGITAL MARKETING AGENCY BASED IN BOSTON</h2>
      </div>
      <h3 className={"subtitle"}>Avantcy Marketing offers you a team of qualified digital marketing experts.
        We take our job seriously and offer you a reasonable means to turn regular online consumers to site visitors.
        Because we are overachievers, we hate to stop halfway. We develop strategies to ensure that these visitors will
        become leads and sales. Our plans help us kill two birds with one stone—helping you reach your business goals
        while doing what we love.</h3>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>SOCIAL MEDIA MARKETING</h2>
          <p>Our Boston marketing services comprises of <strong>social media marketing</strong> and management. Most of
            your prospective customers are on social media daily, and the ability to reach them is the core of social
            media marketing. Avantcy Marketing is here to help you set up plans that build your brand awareness and
            generate income from numerous social media platforms.</p>
          <p className={"text-align-left"}>We perform active marketing on platforms like:</p>
          <ul className={"text-align-left"}>
            <li><strong>Facebook</strong></li>
            <li><strong>Instagram</strong></li>
            <li><strong>LinkedIn</strong></li>
            <li><strong>And more platforms</strong></li>
          </ul>
          <p className={"text-align-left"}>With our services, you can fully enjoy our complete service range with
            benefits such as:</p>
          <ul className={"text-align-left"}>
            <li><strong>Content Management and Posting schedule</strong></li>
            <li><strong>Community Management</strong></li>
            <li><strong>Social media posts (Account Optimization)</strong></li>
            <li><strong>Comment monitoring</strong></li>
          </ul>
          <p>Our customers trust our social media and internet marketing service because we are result-driven. Join us
            today in a partnership that can drive the market towards you while building customer loyalty.</p>
          <a className={"button-red m-top-20"} href={"/marketing-services/social-media-marketing"}>LEARN MORE</a>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img2} id={"choosing-pic"}/>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img3} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>SEARCH ENGINE OPTIMIZATION (SEO)</h2>
            <p>Avantcy Marketing offers you a chance to boost site traffic by maximizing <strong>Search Engine
              Optimization.</strong> We present you with the opportunity to enhance your
              site's <strong>SEO</strong> ranking to earn better leads. Search Engine Optimization is an essential and
              very important aspect of our internet marketing service in Boston, as it is extremely effective in
              generating leads and brand recognitions.</p>
            <p className={"text-align-left"}>By working with us, you can expect:</p>
            <ul className={"text-align-left"}>
              <li><strong>Keywords optimization and rank checking</strong></li>
              <li><strong>Competitor monitoring (links, content, and rankings)</strong></li>
              <li><strong>Local and technical SEO</strong></li>
              <li><strong>SEO strategizing</strong></li>
              <li><strong>Website reports and analysis</strong></li>
              <li><strong>Google my business optimization</strong></li>
              <li><strong>Monthly, quarterly, or annual progress reports</strong></li>
              <li><strong>Other search engine optimization targeted actions and services.</strong></li>
            </ul>
            <p>Digital marketers know that SEO is a channel that drives exceptional business growth. However,
              understanding the value of something doesn't equate to expertise. At Avantcy Marketing, we offer you our
              expertise to ensure that you reach your business goals. Additionally, we provide reports based on an
              agreed duration so that you can easily monitor your progress.</p>
            <a className={"button-red m-top-20"} href={"/marketing-services/seo"}>LEARN MORE</a>
          </div>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>PAY PER CLICK ADVERTISING</h2>
          <p>Are you tired of patiently waiting for organic traffic that doesn't seem to be coming? You may want to try
            <strong> pay-per-click</strong> advertising. We are here to help you pay for better advertisement spots and
            bid for placements that are bound to bring traffic.</p>
          <p>Many people invest in <strong>PPC</strong> and still get no visitors, like water droplets from an empty pump.
            Our goal is to change that narrative. We aim to ensure that the visitors you get on your site make the fee
            you pay insubstantial compared to its effectiveness.</p>
          <p className={"text-align-left"}>We offer you an opportunity to have:</p>
          <ul className={"text-align-left"}>
            <li><strong>Numerous keyword targets</strong></li>
            <li><strong>Lead tracking and transcription</strong></li>
            <li><strong>PPC ads optimization on multiple networks</strong></li>
            <li><strong>Conversion analysis reports and more</strong></li>
          </ul>
          <p>Pay-per-click brings your site to the top of SERPs as advertisement when they type your keywords. However,
            it is easy to waste money on PPC digital marketing in Boston. With the massive room for loopholes, Avantcy
            Marketing ensures that every penny spent on PPC is used to your advantage to yield expected results. </p>
          <a className={"button-red m-top-20"} href={"/marketing-services/pay-per-click"}>LEARN MORE</a>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img4} id={"choosing-pic"}/>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img5} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>WEB DESIGN AND DEVELOPMENT</h2>
            <p>If you feel like your website still lacks the wow factor, you are in the right place! We add flair and
              meaning to your site to effectively display your brand while attracting attention. Your website will
              always be the center of your online marketing efforts. A proper webpage is bound to attract the
              appropriate visitors and lead to:</p>
            <ul className={"text-align-left"}>
              <li><strong>Better conversation rates</strong></li>
              <li><strong>Effective brand capture</strong></li>
              <li><strong>Revenue maximization</strong></li>
              <li><strong>Achieving business goals and more</strong></li>
            </ul>
            <p>For the tech geeks out there, we understand your frustration. We work with
              the latest technology on all of our site (Goodbye WordPress) to ensure longevity of the site and
              maintaining best practices.</p>
            <p className={"text-align-left"}>Our sites are created with:</p>
            <ul className={"text-align-left"}>
              <li><strong>GatsbyJS</strong></li>
              <li><strong>Shopify Custom Storefronts</strong></li>
              <li><strong>MERN Stack</strong></li>
              <li><strong>Tailwind or Bootstrap Grid and other relevant tech stack</strong></li>
            </ul>
            <p>Most times, a customer interacts with your brand first via your website after clicking an ad. We
              understand the essence of a website with the proper structure to reduce bounce rates. We are sure you know
              that too, which is why you are here. You can leave your site design with us and rest easy knowing that a
              team of experts will deliver the best page for your niche.
            </p>
            <a className={"button-red m-top-20"} href={"/marketing-services/web-design"}>LEARN MORE</a>
          </div>
        </div>
      </div>
    </div>
    {/*<div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>LEAD GENERATION</h2>
          <p>Are you struggling to generate leads? Avantcy Marketing equips you with the necessary data, strategies,
            tools, and resources required to gain future customers. We help with plans that attract potential
            clients and nurture their interest up till the final sales.</p>
          <p className={"text-align-left"}>We offer you access to:</p>
          <ul className={"text-align-left"}>
            <li><strong>Valuable accounts and contacts</strong></li>
            <li><strong>Qualified sales appointments and leads</strong></li>
            <li><strong>Visibility on multiple channels</strong></li>
            <li><strong>Numerous tools for optimal sales drive and more</strong></li>
          </ul>
          <p>We present you with an opportunity to meet and exceed your sales targets today.</p>
          <a className={"button-red m-top-20"}>LEARN MORE</a>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img6} id={"choosing-pic"}/>
        </div>
      </div>
    </div>*/}
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>CONTENT MARKETING</h2>
          <p>We make content marketing seamless and hassle-free. State the type of content you need for marketing
            and
            leave the rest to us.</p>
          <p className={"text-align-left"}>We specialize in creating:</p>
          <ul className={"text-align-left"}>
            <li><strong>Blog posts</strong></li>
            <li><strong>Images</strong></li>
            <li><strong>Video content</strong></li>
            <li><strong>Audio content and podcasts</strong></li>
            <li><strong>e-Book</strong></li>
            <li><strong>Infographics</strong></li>
            <li><strong>Emails</strong></li>
            <li><strong>Newsletters</strong></li>
            <li><strong>How-to guides and more</strong></li>
          </ul>
          <p>Avantcy Marketing's internet marketing service in Boston is the best option for content marketing
            needs.
            We ensure that you have a timely, targeted, and relevant content at your request. All you need to do is
            tell us what you want, and we will give you the best team that deliver. You can now focus on your business
            growth and development instead of following up on content marketing strategies.</p>
          <a className={"button-red m-top-20"} href={"/marketing-services/content-marketing"}>LEARN MORE</a>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img7} id={"choosing-pic"}/>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default servicesPage;
